import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
  } from "@web3modal/ethereum";
  
  import { configureChains, createClient } from "wagmi";
  import { polygon } from "wagmi/chains";
  
  export const projectId = '00b2400ef7d7d606ff5f527657ed6a2f';
  // Wagmi client
  const { provider } = configureChains([polygon], [
    w3mProvider({ projectId }),
  ]);
  
  export const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains: [polygon] }),
    provider,
  });
  
  // Web3Modal Ethereum Client
  export const ethereumClient = new EthereumClient(wagmiClient, [polygon]);